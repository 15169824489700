<template>
    <div id="courseIntroductionDetails">
        <div class="box">
            <div class="breadCrumbs">
                <span style="color: #999999;cursor: pointer;" @click="indexClick">首页</span>>
                <span style="color: #999999;cursor: pointer;" @click="courseClick">课程教学</span>>
                <span style="color: #999999;cursor: pointer;" @click="detailsClick">课程介绍</span>>
                <span>课程详情</span>
            </div>
            <div style="width:100%;height: 1000px;display: flex;justify-content: space-between;">
                <div style="width: 100%;" v-if="fileShow == 6">
                    <vue3videoPlay  style="pointer-events: none !important;" :src="`${baseUrl}/${fileSrcUrl}`" ref="videoPlay"
                    id="myvideo"
                    :controlBtns="['audioTrack', 'quality', 'speedRate', 'volume', 'setting', 'pip', 'fullScreen']"
                    :speed="false" @play="onPlay" @pause="onPause" @timeupdate="onTimeupdate" @canplay="onCanplay" />
                </div>

                    <vue-office-excel v-if="fileShow == 1" :src="`${baseUrl}/${fileSrcUrl}`" style="width: 100%;"/>
                    <vue-office-docx v-if="fileShow == 2" :src="`${baseUrl}/${fileSrcUrl}`" style="width: 100%;"/>
                    <vue-office-pdf v-if="fileShow == 3" :src="`${baseUrl}/${fileSrcUrl}`" @error="errHandler" style="width: 100%;"/>
                    <iframe v-if="fileShow == 4" :src="`${baseUrl}/${fileSrcUrl}`" frameborder="0"
                        style="width: 100%; height: 100%"></iframe>

                    <div style="height:100%;width:100%;overflow-y:auto;" v-if="fileShow == 5">
                        <img style="width:100%" v-for="(item, index) in imgList" :key="index" :src="`${baseUrl}/${item}`"
                            alt="" srcset="">
                    </div>


                
                <div class="rightBox">
                    <div
                        style="width: 100%;height: 50px;line-height: 50px;text-align: center;border-bottom: 1px solid #F7F8FA;font-weight: bold;color: #333333;">
                        课程目录
                    </div>
                    <el-collapse v-model="activeNames" accordion style="margin: 10px;">
                        <el-collapse-item v-for="(item, index) in CourseCatalog" :key="index" :name="index">
                            <template #title>
                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
                                    <span style="font-size: 16px;font-weight: 500;color: #333333;padding-left: 20px;">模块{{
                                        index+=1 }}</span><span style="padding-left: 20px;">{{ item.catalogueName }}</span>
                                </div>
                            </template>
                            <div style="margin-left: 0px;margin-top: 10px;background-color: #F7F8FA;line-height: 30px;font-size: 0.5rem;"
                                v-for="(itemChild, indexChild) in item.child" :key="indexChild">
                                <span v-if="ifCarryOut == itemChild.id" 
                                    style="display:inline-block;width:20%;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;float:left">进行中</span>
                                
                                <span v-if="!itemChild.isStudy && ifCarryOut != itemChild.id"
                                    style="display:inline-block;width:20%;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;float:left">未学</span>
                                <span v-if="itemChild.isStudy && ifCarryOut != itemChild.id"
                                    style="display:inline-block;width:20%;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 7px 7px 7px 0px;float:left">已学</span>
                                <p style="display: flex;align-items: center;justify-content: space-around;">
                                    <span style="padding-left: 10px;cursor:pointer;">任务{{ itemChild.isOrder }} {{
                                        itemChild.catalogueName }}</span>
                                    <img @click="videoClick(itemChild)" style="vertical-align: middle;cursor: pointer;"
                                        :src="require('@/assets/img/courseTechingimg/palyer.png')" alt="">
                                    <span v-if="itemChild.isExercises == true" @click="GoHomework(itemChild)"
                                        style="cursor:pointer;display:inline-block;width:80px;height:20px;background:#76C8F6;color:#fff;text-align:center;font-size:12px;line-height:20px;border-radius: 10px;margin-left: 10px;">课后习题</span>
                                </p>
                                <div v-for="(pdfItem, pdfIndex) in itemChild.courseware" :key="pdfIndex">
                                    <span @click="cliPDF(pdfItem)" style="padding-left: 10px;cursor:pointer;">任务{{
                                        pdfIndex+=1 }} {{ pdfItem.fileName }}</span>
                                </div>

                            </div>
                            <!-- <div style="margin-left: 120px;margin-top: 10px;cursor: pointer;" v-for="(item2,index) in item.fileSrc" :key="index" @click="pdfClick(item2)">
                                <p style="padding-top: 10px;"><span>{{ item2.childrenName }}</span> </p>
                            </div>           -->
                        </el-collapse-item>
                    </el-collapse>
                </div>
            </div>
        </div>

        <el-dialog v-model="centerDialogVisible" width="20%" center>
            <span>
                请加入选修课后再进行答题
            </span>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="centerDialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="centerDialogVisible = false">
                        确定
                    </el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import { reactive, toRefs, onMounted, onUnmounted, computed, watch, onBeforeUnmount, onBeforeMount, onBeforeUpdate, onUpdated } from "vue";
import { useRouter ,useRoute} from "vue-router";
import { useStore } from "vuex";
import { getStorage, setStorage } from "@/js/common";
import { backUrl } from "@/js/util";
import 'vue3-video-play/dist/style.css'
import vue3videoPlay from 'vue3-video-play'
import { baseUrl } from '@/js/util.js'
//引入VueOfficeDocx组件
import VueOfficeDocx from '@vue-office/docx'
//引入相关样式
import '@vue-office/docx/lib/index.css'
//引入VueOfficeExcel组件
import VueOfficeExcel from '@vue-office/excel'
//引入相关样式
import '@vue-office/excel/lib/index.css'
import VueOfficePdf from '@vue-office/pdf'
import { ElMessage, ElMessageBox } from "element-plus";
import { F_Insert_playRecording, F_Get_playRecordingData, f_go_exercises, F_Insert_playnumstatistics } from "@/js/homeListApi";
import { Show_File } from '../../../js/public'


export default {
    components: { VueOfficeDocx, VueOfficeExcel, VueOfficePdf, vue3videoPlay },

    setup() {
        const route = useRoute();
        const router = useRouter();
        const store = useStore();
        const state = reactive({
            videoPlay: null,
            baseUrl: baseUrl,
            activeNames: 0,
            fileSrcUrl: '',
            kechengData: [
                // {   
                //     danyuanNum:'第1章',
                //     nameTitle:'绪论-结构力学',
                //     fileSrc:[
                //         {
                //             childrenName:'第1章绪论-结构力学.pdf',
                //             childrenFileUrl:'static/PPT/第1章绪论-结构力学.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第2章',
                //     nameTitle:'几何组成分析',
                //     fileSrc:[
                //         {
                //             childrenName:'第2章几何组成分析.pdf',
                //             childrenFileUrl:'static/PPT/第2章 几何组成分析.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第3章',
                //     nameTitle:'静定结构的受力分析（梁—多跨梁）',
                //     fileSrc:[
                //         {
                //             childrenName:'第3章静定结构的受力分析（梁—多跨梁）.pdf',
                //             childrenFileUrl:'static/PPT/第3章 静定结构的受力分析（梁—多跨梁）.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第4章',
                //     nameTitle:'影响线及其应用',
                //     fileSrc:[
                //         {
                //             childrenName:'第4章影响线及其应用.pdf',
                //             childrenFileUrl:'static/PPT/第4章 影响线及其应用.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第5章',
                //     nameTitle:'虚功原理与结构位移计算',
                //     fileSrc:[
                //         {
                //             childrenName:'第5章虚功原理与结构位移计算.pdf',
                //             childrenFileUrl:'static/PPT/第5章 虚功原理与结构位移计算.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第6章',
                //     nameTitle:'力法',
                //     fileSrc:[
                //         {
                //             childrenName:'第6章 力法.pdf',
                //             childrenFileUrl:'static/PPT/第6章 力法.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第7章',
                //     nameTitle:'位移法',
                //     fileSrc:[
                //         {
                //             childrenName:'第7章 位移法.pdf',
                //             childrenFileUrl:'static/PPT/第7章 位移法.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第8章',
                //     nameTitle:'渐进法及其他算法简述',
                //     fileSrc:[
                //         {
                //             childrenName:'第8章 渐进法及其他算法简述.pdf',
                //             childrenFileUrl:'static/PPT/第8章 渐进法及其他算法简述.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第9章',
                //     nameTitle:'矩阵位移法',
                //     fileSrc:[
                //         {
                //             childrenName:'第9章 矩阵位移法.pdf',
                //             childrenFileUrl:'static/PPT/第9章 矩阵位移法.pdf'
                //         }
                //     ]
                // },{   
                //     danyuanNum:'第10章',
                //     nameTitle:'结构动力计算基础',
                //     fileSrc:[
                //         {
                //             childrenName:'10-1 综述(动力计算的特点和动力自由度).pdf',
                //             childrenFileUrl:'static/PPT/10-1 综述(动力计算的特点和动力自由度).pdf'
                //         },{
                //             childrenName:'10-2单自由度体系的自由振动.pdf',
                //             childrenFileUrl:'static/PPT/10-2单自由度体系的自由振动.pdf'
                //         },{
                //             childrenName:'10-3单自由度体系的强迫振动.pdf',
                //             childrenFileUrl:'static/PPT/10-3单自由度体系的强迫振动.pdf'
                //         },{
                //             childrenName:'10-4阻尼对振动的影响.pdf',
                //             childrenFileUrl:'static/PPT/10-4阻尼对振动的影响.pdf'
                //         },{
                //             childrenName:'10-5两个自由度体系的自由振动.pdf',
                //             childrenFileUrl:'static/PPT/10-5两个自由度体系的自由振动.pdf'
                //         },{
                //             childrenName:'10-6两个自由度体系在简谐荷载下的强迫振动.pdf',
                //             childrenFileUrl:'static/PPT/10-6两个自由度体系在简谐荷载下的强迫振动.pdf'
                //         },
                //     ]
                // },
            ],
            CourseCatalog: getStorage('CourseCatalog'),//课程目录
            videoUrl: '',
            NewTime: '',
            courseContentId: '',
            schedule: 0,
            courseType: getStorage('courseType'),
            ifcourse: getStorage('ifcourse'),
            centerDialogVisible: false,
            videoORpdf: true,
            fileShow: '',
            imgList: [],
            fileName2:'',
            ifCarryOut:'',

        })
        onBeforeMount(() => {
            let itemChildID = route.query.itemChildID
            if (itemChildID) {
                state.CourseCatalog.forEach((res,index)=>{
                    res.child.forEach((res2,index2)=>{
                        if (res2.id == itemChildID) {
                            state.activeNames = index
                            // return
                            state.fileSrcUrl = res2?.courseCover?.filePath;
                            state.courseContentId = res2?.id;
                            state.fileName2 = res2?.courseCover?.fileName;
                            state.ifCarryOut = res2?.id;
                        }
                    })
                })
            }else if (!state.CourseCatalog.length == 0 && !itemChildID) {
                state.fileSrcUrl = state?.CourseCatalog[0]?.child[0]?.courseCover?.filePath;
                state.fileName2 = state?.CourseCatalog[0]?.child[0]?.courseCover?.fileName;
                state.courseContentId = state?.CourseCatalog[0]?.child[0]?.id;
                state.ifCarryOut = state?.CourseCatalog[0]?.child[0]?.id;
            }

                const index = state.fileName2.lastIndexOf('.');
                const resvles = state.fileName2.substring(index + 1, state.fileName2.length);
                console.log(resvles, '石星雨');
                if (resvles == 'xlsx') {
                    state.fileShow = 1;
                } else if (resvles == 'docx') {
                    state.fileShow = 2;
                } else if (resvles == 'pdf') {
                    state.fileShow = 4;
                } else if (resvles == 'png' || resvles == 'jpg') {
                    state.fileShow = 4;
                } else if (resvles == 'ppt' || resvles == 'pptx') {
                    state.fileShow = 5;
                    methods.Show_File(2, state.fileSrcUrl)
                } else if (resvles == 'doc') {
                    methods.Show_File(1, state.fileSrcUrl)
                } else if (resvles == 'mp4') {
                    state.fileShow = 6;
                } else {
                    ElMessage({
                        type: 'info',
                        message: '暂不支持查看此文件类型',
                    })
                }

            methods.FplayRecordingData();
        })
        onMounted(() => {
            console.log(state.CourseCatalog, '课程目录222');
            // state.fileSrcUrl = state.CourseCatalog[0].child[0].courseCover;
            // state.courseContentId = state.CourseCatalog[0].child[0].id;
            // methods.FplayRecordingData();
        })
        const methods = {
            Show_File(type, filePath) {
                let params = {
                    type: type,
                    filePath: filePath
                }
                Show_File(params).then(res => {
                    if (res.data.code == 1) {
                        if (type == 2) {
                            state.imgList = []
                            res.data.data.forEach(element => {
                                state.imgList.push(element)
                            });
                        } else {
                            state.fileSrcUrl = res.data.data;
                            state.fileShow = 4;
                        }

                    }
                })
            },
            errHandler(err) {
                console.log(err);
            },
            cliPDF(pdfItem) {
                // state.videoORpdf = false
                //pdfItem.filePath
                //pdfItem.Id
                //fileShow
                state.fileSrcUrl = pdfItem.filePath
                const index = pdfItem.fileName.lastIndexOf('.');
                const resvles = pdfItem.fileName.substring(index + 1, pdfItem.fileName.length);
                console.log(resvles, '石星雨');
                if (resvles == 'xlsx') {
                    state.fileShow = 1;
                } else if (resvles == 'docx') {
                    state.fileShow = 2;
                } else if (resvles == 'pdf') {
                    state.fileShow = 4;
                    
                } else if (resvles == 'png' || resvles == 'jpg') {
                    state.fileShow = 4;
                } else if (resvles == 'ppt' || resvles == 'pptx') {
                    state.fileShow = 5;
                    methods.Show_File(2, pdfItem.filePath)
                } else if (resvles == 'doc') {
                    methods.Show_File(1, pdfItem.filePath)
                } else {
                    ElMessage({
                        type: 'info',
                        message: '暂不支持查看此文件类型',
                    })
                }

            },
            //F_Insert_playRecording添加播放记录
            FplayRecording() {
                let datas = {
                    courseContentId: state.courseContentId,//课程内容id
                    schedule: state.NewTime.toString(),//播放的进度
                    userId: getStorage('userId'),//老师/学生id
                }
                F_Insert_playRecording(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log('添加播放记录');
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            playnumstatistics() {
                let datas = {
                    courseContentId: state.courseContentId,//课程内容id
                    userId: getStorage('userId'),//老师/学生id
                }
                F_Insert_playnumstatistics(datas).then((res) => {
                    if (res.data.code == 1) {
                        console.log('添加播放次数');
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            //F_Get_playRecordingData获取播放记录
            FplayRecordingData() {
                let datas = {
                    courseContentId: state.courseContentId,//课程内容id
                    userId: getStorage('userId'),//老师/学生id
                }
                F_Get_playRecordingData(datas).then((res) => {
                    if (res.data.code == 1) {
                        state.schedule = res.data.data?.schedule;
                        console.log(res.data.data?.schedule, '获取播放记录时间');

                        let myvideo = document.getElementById("myvideo");
                        myvideo.currentTime = res.data.data?.schedule
                        // state.videoPlay.currentTime = res.data.data.schedule
                        console.log(myvideo, 'myvideo');
                    } else {
                        ElMessage.error(res.data.message)
                    }
                })
            },
            onPlay(ev) {
                console.log(ev, '播放')
                methods.playnumstatistics()
            },
            onPause(ev) {
                console.log(ev, '暂停')
                methods.FplayRecording();
            },
            onTimeupdate(ev) {
                // console.log(ev.srcElement.currentTime, '时间更新')
                state.NewTime = ev.srcElement.currentTime
                // state.schedule = ev.srcElement.currentTime
                console.log(state.NewTime, '时间更新')
            },
            onCanplay(ev) {
                console.log(ev, '可以播放')
            },
            //点击对应视频
            videoClick(itemChild) {
                state.ifCarryOut = itemChild.id;
                state.courseContentId = itemChild.id;
                state.fileSrcUrl = itemChild.courseCover?.filePath
                const index = itemChild.courseCover?.fileName.lastIndexOf('.');
                const resvles = itemChild.courseCover?.fileName.substring(index + 1, itemChild.courseCover?.fileName.length);
                console.log(resvles, '石星雨');
                if (resvles == 'xlsx') {
                    state.fileShow = 1;
                } else if (resvles == 'docx') {
                    state.fileShow = 2;
                } else if (resvles == 'pdf') {
                    state.fileShow = 4;
                } else if (resvles == 'png' || resvles == 'jpg') {
                    state.fileShow = 4;
                } else if (resvles == 'ppt' || resvles == 'pptx') {
                    state.fileShow = 5;
                    methods.Show_File(2, itemChild.courseCover?.filePath)
                } else if (resvles == 'doc') {
                    methods.Show_File(1, itemChild.courseCover?.filePath)
                } else if (resvles == 'mp4') {
                    state.fileShow = 6;
                } else {
                    ElMessage({
                        type: 'info',
                        message: '暂不支持查看此文件类型',
                    })
                }
                methods.FplayRecordingData();
            },
            //点击课后习题
            GoHomework(itemChild) {
                console.log("🚀 ~ file: courseIntroductionDetails.vue:291 ~ GoHomework ~ itemChild:", itemChild)

                if (state.ifcourse == true || state.courseType == 1) {
                    let datas = {
                        courseSectionId: itemChild.id
                    }
                    f_go_exercises(datas).then((res) => {
                        if (res.data.code == 1) {
                            // assessment
                            setStorage('assessment', res.data.data)
                            console.log(res.data.data, '石星雨');
                            router.push('/home/HomeworkAfterClass');
                        } else {
                            ElMessage.error(res.data.message)
                        }
                    })
                } else {
                    state.centerDialogVisible = true
                }






            },
            indexClick() {
                store.commit("SetnarbarMenuActive", 1);
                setStorage("narbarMenuActive", 1);
                router.push('/home/index');
            },
            courseClick() {
                router.push('/home/courseTeaching');
            },
            detailsClick() {
                router.push('/home/courseDetailsCopy');
            },
            pdfClick(item) {
                state.fileSrcUrl = item.childrenFileUrl;
                console.log(state.fileSrcUrl);
            },
        }
        onBeforeUpdate(() => {
            // methods.FplayRecording();
            console.log('onBeforeUpdate');
        }),
            onUpdated(() => {

                console.log('onUpdated');
            })
        onBeforeUnmount(() => {
            methods.FplayRecording();
        })
        return {
            ...toRefs(state),
            ...methods,
        }
    }
}
</script>
<style  lang="scss" scoped>
#courseIntroductionDetails {
    height: 100%;
    position: relative;
    background: #F7F8FA;

    .box {
        width: 66.5%;
        height: 100%;
        margin: 0 auto;
    }

    .breadCrumbs {
        margin-top: 10px;
        margin-bottom: 10px;
        font-size: 12px;
    }

    .rightBox {
        width: 354px;
        height: auto;
        background: #fff;
        float: right;
    }

    :deep .el-collapse-item__header {
        background: #F7F8FA;
    }

    :deep .el-collapse-item__wrap {
        border: none;
    }

    .d-player-wrap[data-v-01791e9e] {
        //--primary-color: 64,158,255; 
        width: auto !important;
        height: auto !important;
    }
    

}
</style>